<template>
  <modal-brandme :show_modal="open_modal_login">
    <template #body>
      <b-img :src="require('@/assets/images/logo/logo_brandme-square.jpeg')" class="image-brandme-modal"></b-img>
      <div class="p-1 pt-4">
        
        <div class="h2 text-center">{{ $t('login.title') }}</div>
        <div class="text-center">{{ $t('login.text') }}</div>
        
        <!-- social buttons -->
        <div class="auth-footer-btn d-flex justify-content-center mt-2">
          <GoogleLogin class="google-btn" :params="params" :onSuccess="onSuccess" :onFailure="onFailure">
            <span class="google-icon" :style="`background: url(${require('@/assets/images/google/google.svg')}) transparent 5px 50% no-repeat;`"></span>
            <span class="google-text">{{ $t('login.googleLogin') }}</span>
          </GoogleLogin>
        </div>

        <!-- divider -->
        <div class="divider my-2">
          <div class="divider-text">
            {{ $t('login.divider') }} 
          </div>
        </div>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >
            <!-- email -->
            <b-form-group>
              <label for="login-email">{{ $t('login.email') }}</label>
              <validation-provider
                #default="{ errors }"
                :name="$t('login.email')"
                :vid="$t('login.email')"
                rules="required|email"
              >
                <b-form-input
                  id="login-email"
                  v-model="user_email"
                  :state="errors.length > 0 ? false:null"
                  name="login-email"
                  :placeholder="$t('login.email')"
                  trim
                />
                <!-- <small class="text-danger">{{ errors[1] }}</small> -->
              </validation-provider>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">{{ $t('login.password') }}</label>
                <b-link :to="{name:'auth-forgot-password'}" target="_blank">
                  <small>{{ $t('login.forgotPassword') }}</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                :name="$t('login.password')"
                :vid="$t('register.password')"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    :placeholder="$t('register.password')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password_toggle_icon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                {{ $t('login.rememberPassword') }}
              </b-form-checkbox>
            </b-form-group>

            <!-- submit buttons -->
            <b-button
              type="submit"
              class="blue-button"
              variant="blue-button"
              block
              :disabled="invalid"
            >
              {{ $t('login.button') }}
            </b-button>
          </b-form>
        </validation-observer>
        <div class="text-center mt-2">
          <span>{{ $t('login.new') }}</span>
          <b-link :to="{name:'auth-register'}" target="_blank">
            <span>&nbsp;{{ $t('login.create') }}</span>
          </b-link>
        </div>
      </div>
    </template>
  </modal-brandme>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BLink,
  BInputGroup,
  BButton,
  BFormCheckbox,
  BInputGroupAppend,
  BImg,
} from 'bootstrap-vue';
import modalBrandme from '@/views/components/modal/ModalBrandme.vue';
import GoogleLogin from 'vue-google-login';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import service from '@/services/others';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import useJwt from '@/auth/jwt/useJwt';
import { setPermissions, setuserData } from '@/libs/utils/user'
import abilities from '@/libs/acl/abilities'
import { getHomeRouteForLoggedInUser } from '@/auth/utils';
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms';

export default {
  name: 'ModalLogin',
  components: {
    BImg,
    BForm,
    BFormGroup,
    BFormInput,
    BLink,
    BInputGroup,
    BButton,
    BFormCheckbox,
    BInputGroupAppend,
    modalBrandme,
    GoogleLogin,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    open_modal: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      setuserData,
      setPermissions,
      required,
      params: {
        client_id: '41988944628-epj92qelccpcub5snlh6p08l4mnelmql.apps.googleusercontent.com'
      },
      open_modal_login: this.open_modal,
      password: '',
      user_email: '',
      status: ''
    }
  },
  mixins: [togglePasswordVisibility],
  computed: {
    password_toggle_icon () {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    showErrorLogin(title) {
      loadToastificationContent().then((component) => {
        this.$toast({
          component: component.default,
          position: 'top-right',
          props: {
            title,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          }
        })
      })
    },
    login () {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            email: this.user_email,
            password: this.password,
            is_app: true
          })
            .then(re => {
              const response = re.data.response;
              if (response.user.main_group && response.user.main_group.name === 'realty') return this.showErrorLogin(this.$t('campaigns.error_status'))
              this.setUser(response)     
            })
            .catch(error => {
              const code = error.response && error.response.data && error.response.data.response.code ? error.response.data.response.code : null
              if (code === 'accounts.emailDoesNotExists') {
                this.showErrorLogin(this.$t('login.mailNotExists'))
              } else if (code === 'accounts.inactiveAccount') {
                this.showErrorLogin(this.$t('login.inactiveAccount'))
              } else if (code === 'accounts.BadLogin') {
                this.showErrorLogin(this.$t('login.badPassword'))
              } else {
                this.showErrorLogin(this.$t('campaigns.error_status'))
              }
            })
        }
      })
    },
    onSuccess(googleUser) {
      const keys = Object.keys(googleUser);
      let index = '';
      keys.forEach(key => {
        if (googleUser[key].id_token) index = googleUser[key];
      });
      service.googleLogin({token: index.id_token}).then((response) => {
        this.setUser(response);
      }).catch(() => {
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: 'top-left',
            props: {
              title: this.$t('login.errorGoogleBrand'),
              text: this.$t('login.textGoogleBrand'),
              icon: 'AlertCircleIcon',
              variant: 'warning'
            }
          })
        })
      });
    },
    setUser(data) {
      useJwt.setToken(data.access_token)
      useJwt.setRefreshToken(data.refresh_token)
     
      setPermissions(data.user);
      this.readAbility = abilities.getAbilitie(data.user)
      if (data.user.main_group) location.reload();
      this.open_modal_login = false;
      const show_connect_message = data.user.main_group && data.user.main_group.name === 'creator'
      localStorage.setItem('show_connect_message', show_connect_message)

      setuserData(data, this.readAbility)
      this.$ability.update(this.readAbility)
      this.$store.commit('appConfig/UPDATE_IS_VERIFIED', data.user.is_verified)
      this.$router.push(getHomeRouteForLoggedInUser(data.user))
        .then(() => {
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-right',
              props: {
                title: `${this.$t('login.welcome')}  ${data.user.first_name || data.user.last_name}`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: this.$t('login.success')
              }
            })
          })
        })
      if (!data.user.main_group) {
        this.$router.push({name: 'auth-roll'}) 
      }
    },
    onFailure() {
      loadToastificationContent().then((component) => {
        this.$toast({
          component: component.default,
          position: 'top-right',
          props: {
            title: this.$t('login.errorGoogle'),
            icon: 'AlertCircleIcon',
            variant: 'warning',
          }
        })
      })
    },
  }
}
</script>

<style scoped>
.image-brandme-modal {
  width: 80px;
  height: 80px;
  margin: 0px auto;
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 20%);
}
</style>